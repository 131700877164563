import { Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss']
})
export class ProgressSpinnerComponent implements OnInit {
  @Input() displayProgressSpinner: boolean;

  public color = 'primary';
  public mode = 'indeterminate';
  public value = 50;
  public backdropEnabled = true;
  public diameter = 100;
  public strokeWidth= 0;
  public positionGloballyCenter = true;

  constructor() {}

  ngOnInit() {}
}
