import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AuthorizeAgreementComponent } from '../authorize-agreement/authorize-agreement.component';
import { Constants } from '../constants/constants';
import { PaymentAuthService } from '../services/payment-auth.service';
import { PAYMENTTYPE, PAYMENTTYPECODE } from 'src/app/models/payment-type-enum'
import { ErrorDialogModalComponent } from '../error-dialog-modal/error-dialog-modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-auth-terms-condition',
  templateUrl: './auth-terms-condition.component.html',
  styleUrls: ['./auth-terms-condition.component.scss']
})
export class AuthTermsConditionComponent implements OnInit {
  public contextInfo: any;
  public constants = Constants.CONTENTCONSTANTS;
  public termsLine1: any;
  public termsLine2: any;
  public termsLine3: any;
  public termsLineBC: any;
  public termsLineRBC: any;
  public termsLineACH: any;
  public termsLineRACH: any;
  public isTokenGenerated = false;
  public displayProgressSpinner = false;

  constructor(private router: Router,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AuthTermsConditionComponent>,   
    private paymentAuthService: PaymentAuthService) { }

  ngOnInit(): void {
    this.contextInfo = this.paymentAuthService.contextData;
    this.setTermsNCondition();   
  }

  setTermsNCondition() {
    const dt = moment(new Date()).format('LL');
    this.termsLine1 = this.constants.termsNCondition.line1.replace('[USER]', this.contextInfo.memberName);
    this.termsLine2 = this.constants.termsNCondition.line2;
    
    let paymentMethod = this.paymentAuthService.setPaymentMethod(this.contextInfo.cardTypeCode);
    let paymentPlan = "Monthly"; //TODO To be read from Service response
    if (paymentMethod == this.constants.paymentMethod.creditCard) {
      if (paymentPlan == "Annual") {
        this.termsLine3 = this.constants.termsNCondition.lineBC.replace('[CARD]', paymentMethod.toLowerCase());
      } else {
        this.termsLine3 = this.constants.termsNCondition.lineRBC.replace('[CARD]', paymentMethod.toLowerCase());
      }
    } else if (paymentMethod == this.constants.paymentMethod.debitCard) {
      if (paymentPlan == "Annual") {
        this.termsLine3 = this.constants.termsNCondition.lineACH;
      } else {
        this.termsLine3 = this.constants.termsNCondition.lineRACH;
      }
    }
    this.termsLine3 = this.termsLine3.replace('[DATE]', dt);
    
    
  }

  authPayment() {
    this.displayProgressSpinner = true;
    let paymentMethod = this.paymentAuthService.setPaymentMethod(this.contextInfo.cardTypeCode);
    let ccNo = '';
    let achNo = '';
    paymentMethod === this.constants.paymentMethod.creditCard ? ccNo = this.contextInfo.accountNumber : achNo = this.contextInfo.accountNumber;
    const authInfo = {
      policyNumber: this.contextInfo.policyNumber.toString(),
      termNumber: this.contextInfo.policyTermNumber.toString(),
      lastFourDigitsCCNo: ccNo.toString(),
      achAccount: achNo.toString(),
      paymentType: this.setPaymentType(this.contextInfo.cardTypeCode),
      authInd: this.constants.yesOrNo.yes,
      salesChannel: this.constants.sourceSystem.system
    };
    const accountId = this.contextInfo.accountId;
    this.paymentAuthService.paymentAuthorization(authInfo, accountId).subscribe({
      next: (res: any) => {
        console.log(res);
      },
      error: (err) => {
        this.displayProgressSpinner = false;
        this.showErrorMessage();
      },
      complete: () => {
        this.displayProgressSpinner = false;
        this.openAuthAgreement();
      }
    });
  }

  five9authPayment() {
    this.displayProgressSpinner = true;
    const authInfo = {
      contextId: sessionStorage.getItem('contextId'),
      contextFlag: 'Y',
      listName: environment.five9ListName
    };
    this.paymentAuthService.five9PaymentAuthorization(authInfo).subscribe({
      next: (res: any) => {
        console.log(res);
      },
      error: (err) => {
        this.displayProgressSpinner = false;
        this.showErrorMessage();
      },
      complete: () => {
        this.displayProgressSpinner = false;
        this.openAuthAgreement();
      }
    });
  }

  navigatetoAgreement() {
    if (this.contextInfo.source === 'GIN' || this.contextInfo.source === 'REN') {
      this.authPayment();
    }
    else {
      this.five9authPayment();
    }
  }

  openAuthAgreement() {
    let dialogRefChild = this.dialog.open(AuthorizeAgreementComponent, {
      width: '500px',
      height: '500px'
    });
    this.dialogRef.close();
  }

  showErrorMessage() {
    this.dialog.open(ErrorDialogModalComponent, {
      width: '400px',
      height: '250px',
      data: {
        title: "Error",
        content: this.constants.errorMessages.apiErrorMessage
      }
    });
  }

  setPaymentType(typeCode: any) {
    let paymentType = '';
    switch (typeCode.toUpperCase()) {
      case PAYMENTTYPECODE.AX:
        paymentType = PAYMENTTYPE.AMEX;
        break;
      case PAYMENTTYPECODE.DI:
        paymentType = PAYMENTTYPE.DISC;
        break;
      case PAYMENTTYPECODE.MC:
        paymentType = PAYMENTTYPE.MASTER;
        break;
      case PAYMENTTYPECODE.VI:
        paymentType = PAYMENTTYPE.VISA;
        break;
      case PAYMENTTYPECODE.CHECKING:
        paymentType = PAYMENTTYPE.CHECKING;
        break;
      case PAYMENTTYPECODE.SAVING:
      case PAYMENTTYPECODE.SAVINGS:
        paymentType = PAYMENTTYPE.SAVING;
        break;
    }
    return paymentType;
  }
}
