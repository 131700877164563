export const FormValidationConstants = {
    patternValidation: {
      lastName: '^[a-zA-Z0-9][a-zA-Z0-9 ]*[a-zA-Z0-9]*$',
      phoneNumber: '^[0-9-0-9-0-9]*$',
      creditCard: '^[0-9]*$'
    },
    lengthValidation: {
      phoneMinLength: 10,
      phoneNumberLength: 12,
      lastNameLength: 60,
      creditCardMinLength: 4
    }
  };
  