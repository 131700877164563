<div mat-dialog-content>
  <p>
    {{termsLine1}}
    <br />
    {{termsLine2}}
  </p>
  <p> {{termsLine3}} </p>
</div>

<div mat-dialog-actions class="justify-content-center">
  <button mat-raised-button color="primary" class="custom-submit-button" (click)="navigatetoAgreement()">I
    Agree</button>
</div>
<app-progress-spinner class="example-margin" [displayProgressSpinner]="displayProgressSpinner"> </app-progress-spinner>
