import { Injectable } from '@angular/core';
import { DocumentRequest } from '../../models/document-request.model';
import { DocumentResponse } from '../../models/document-response.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { v4 as uuid } from 'uuid';


@Injectable({
  providedIn: 'root'
})
export class OpentextService {
  constructor(
    private http: HttpClient
  ) { }

  getAgreement(documentsRequest: any): Observable<DocumentResponse> {
    const url = environment.baseUrl + environment.distributedDocumentsUrl;
    const accessToken = sessionStorage.getItem('accessToken');
    const TargetEnvironment = environment.distributedDocumentsTargetEnv
    var messageId = uuid();
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + accessToken)  
      .set('client_id', environment.apiKey)
      .set('X-NW-Message-ID', messageId)
      .set('TargetEnvironment', TargetEnvironment);

    return this.http.post<DocumentResponse>(url, documentsRequest, { headers });
  }
}
