import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from './shared/constants/constants';
import jwt_decode from 'jwt-decode';
import { PaymentAuthService } from './shared/services/payment-auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ACHAuthorization';
  public imageConstants = Constants.IMAGECONSTANTS;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private paymentAuthService: PaymentAuthService) {
    this.checkUserIsAuthenticated();
  }

  checkUserIsAuthenticated() {
    const url = window.location.href;
    if (url.lastIndexOf('&id_token=') !== -1) {
      this.redirectToLanding(url);
    }
    else if (url.lastIndexOf('contextId=') !== -1 && url.lastIndexOf('otp=') !== -1) {
      this.retriveContextKeys(url);
    }
    else {
      this.router.navigate(['authpayment']);
    }
  }

  retriveContextKeys(url: string) {
    const contextId = url.substring(url.lastIndexOf('contextId=') + 10, url.lastIndexOf('&otp'));
    const otp = url.substring(url.lastIndexOf('otp=') + 4, url.length);
    if (contextId && otp) {
      sessionStorage.setItem('contextId', contextId);
      sessionStorage.setItem('otp', otp);
      this.router.navigate(['authpayment']);
    }
  }

  redirectToLanding(url: string) {
    let enterpriseToken = url.substring(url.lastIndexOf('&id_token=') + 10, url.lastIndexOf('&state'));
    const accessToken = url.substring(url.lastIndexOf('access_token=') + 13, url.lastIndexOf('&token_type'));
    sessionStorage.setItem('accessToken', accessToken);
    if (enterpriseToken) {
      const decodedToken = jwt_decode(enterpriseToken);
      this.paymentAuthService.contextData = decodedToken["meta"];
      //navigate to landing
      this.router.navigate(['authpayment']);
    }
  }
}
