export const environment = {
  production: false,

  baseUrl: 'https://api-stage.nationwide.com/',
  apiKey: 'bDR18DxYWYJt5MCk058IF023k6pafv7A',

  accountsUrl: 'account-management/pet-php-accounts/v1/',
  distributedDocumentsUrl: 'documentation-management/distributed-documents/v1/documents',
  contentCacheUrl: 'security-processing/context-cache/v1/contexts/',

  distributedDocumentsTargetEnv: 'IterationTest',
  five9ListName: 'ContextCacheStage',

  newRelic: '/assets/js/newrelic.js',
  newRelicApplicationID: '718300834',

  ensightenBootstrap: '//tags.nationwide.com/test/Bootstrap.js',
  
  nationwiideRedirectUrl: 'security-processing/enterprise-user-auth/v2/authorize?client_id=[clientId]&redirect_uri=https://paymentauth-stg.petinsurance.com&response_type=id_token+token&nonce=myNonce&scope=openid&realm=unidentified&auth_method=context-cache&auth_id_otp=[otp]&auth_id_contextId=[contextId]&auth_id_accountNumber=[accountNumber]'
};

