export const Constants = {
    IMAGECONSTANTS: {
        nationwideLogo: '/assets/images/nationwide-logo.png'
    },
    CONTENTCONSTANTS: {
        termsNCondition: {
            line1: 'Hello [USER]',
            line2: 'To continue with your application process, please agree to the following terms and conditions:',            
            lineBC: 'Your authorization today, [DATE], will be used by Nationwide, to originate a one-time [CARD] charge, as allowed by law. Visit your Nationwide Pet Account Access Page at my.petinsurance.com to view amount, card and date details. You further authorize us to electronically credit your card account for any refund of that charge which may at any time be due to you from Nationwide, and to electronically debit or credit your card account for corrections, as necessary. Please see your policy documents for any applicable information regarding refunds or cancellation policies. If you wish to revoke this authorization you will need to provide another form of payment. Please contact us with inquires at 800-540-2016 during normal business hours Monday through Saturday located on the website.',
            lineRBC: 'Your authorization today, [DATE] will be used by Nationwide to originate recurring [CARD] charges, as allowed by law. Visit your Nationwide Pet Account Access Page at my.petinsurance.com to view amount, card and date details. On a monthly basis, starting today and continuing monthly thereafter on the policy effective date, your payment amount as shown on your Nationwide Pet Account Access Page at my.petinsurance.com will be charged on or after the due dates. If your monthly payment changes by at least $5.00, we’ll notify you by emailing or sending you an updated summary. You further authorize us to electronically credit your card account for any refund of that charge which may at any time be due to you from Nationwide, and to electronically debit or credit your card account for corrections, as necessary. Please see your policy documents for any applicable information regarding refunds or cancellation policies. If you wish to revoke this authorization you will need to provide another form of payment or cancel your policy. Please contact us with inquires at 800-540-2016 during normal business hours Monday through Saturday located on the website.',
            lineACH: 'Your authorization today, [DATE] will be used by Nationwide to originate recurring ACH debit entries. On a monthly basis, starting today and continuing monthly thereafter on the policy effective date, your payment amount as shown on your Nationwide Pet Account Access Page at my.petinsurance.com will be charged on or after the due dates. If your monthly payment changes by at least $5.00, we’ll notify you by emailing or sending you an updated summary. You further authorize us to electronically credit this account as needed for corrections, as necessary, and to electronically credit (and/or correct by electronic debit) any refund, credit or other amount which may at any time be due to you from Nationwide, to your bank account. If you wish to revoke this authorization you will need to provide another form of payment or cancel your policy. Please contact us with inquires at 800-540-2016 during normal business hours Monday through Saturday located on the website.',
            lineRACH: 'Your authorization today, [DATE], will be used by Nationwide to originate a one-time ACH debit entry, as allowed by law. Visit your Nationwide Pet Account Access Page at my.petinsurance.com to view amount, account and date details.  You further authorize us to electronically debit or credit your account, for corrections, as necessary, and to electronically credit (and/or correct by electronic debit) any refund, credit or other amount which may at any time be due to you from Nationwide, to your bank account.  If you wish to revoke this authorization you will need to provide another form of payment or cancel your policy. Please contact us with inquires at 800-540-2016 during normal business hours Monday through Saturday located on the website.'            
        },
        yesOrNo: {
            yes: 'Y'
        },
        sourceSystem: {
            system: 'PetLandPg'
        },
        paymentMethod: {
            creditCard: 'Credit Card',
            debitCard: 'Debit Card'
        },
        agreementView: {
            successMessage: 'Thank you for protecting your pet with Nationwide.',
            agreementLinkLabel: 'Click here to print out your agreement'
        },
        errorMessages: {
            unauthorisedMessage: 'Your entry does not match our records. Please check your information and try again.',
            attemptExceedeMessage: 'We were unable to locate your account. Please notify your Member Care Representative or Licensed Sales Agent.',
            apiErrorMessage: "An error occurred. Please try again."
        }
    }
}

