export enum PAYMENTTYPE {
    VISA = 'VISA',
    MASTER = 'Master',
    AMEX = 'AMEX',
    DISC = 'DISC',
    CHECKING = 'CHECKING',
    SAVING = 'SAVING'
}

export enum PAYMENTTYPECODE {
    VI = 'VI',
    MC = 'MC',
    AX = 'AX',
    DI = 'DI',
    CHECKING = 'CHECKING',
    SAVING = 'SAVING',
    SAVINGS = 'SAVINGS'
}