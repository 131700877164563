import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthTermsConditionComponent } from '../auth-terms-condition/auth-terms-condition.component';
import { FormValidationConstants } from '../constants/form-validation-constants';
import { MatDialog } from '@angular/material/dialog';
import { Constants } from '../constants/constants';
import { PaymentAuthService } from '../services/payment-auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-auth-payment',
  templateUrl: './auth-payment.component.html',
  styleUrls: ['./auth-payment.component.scss']
})
export class AuthPaymentComponent implements OnInit {
  public formValidation = FormValidationConstants;
  public rbcLandingForm: any;
  public contextId: any;
  public otp: any;
  public contextInfo: any;
  public isUnauthorized = false;
  public isAttemptsExceeded = false;
  public constants = Constants.CONTENTCONSTANTS;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private paymentAuthService: PaymentAuthService,
    private fb: FormBuilder,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {   
    this.contextInfo = this.paymentAuthService.contextData;
    this.createForm();
    this.checkUserIsAuthorised();
  }

  checkUserIsAuthorised() {
    const accessToken = sessionStorage.getItem('accessToken');
    if (accessToken && this.contextInfo) {
      this.openTermsNCondition();
    }
    else {
      this.contextId = sessionStorage.getItem('contextId');
      this.otp = sessionStorage.getItem('otp');  
      let userAttempts = Number(sessionStorage.getItem('userAttempts')); 
      if (userAttempts > 0 && userAttempts <= 3) {
        this.isUnauthorized = true;
      }
    }
  }  

  createForm() {
    const patternValidation = this.formValidation.patternValidation;
    const lengthValidation = this.formValidation.lengthValidation;
    this.rbcLandingForm = this.fb.group({
      cardLastFourDigit: new FormControl('', [Validators.required,
      Validators.pattern(patternValidation.creditCard),
      Validators.minLength(lengthValidation.creditCardMinLength)])
    });
  }

  numbersOnly(event: KeyboardEvent) {
    return (event.key >= '0' && event.key <= '9') || event.key === ' ' || event.key === 'backspace';
  }

  authenticateUser() {
    if (this.rbcLandingForm.valid) {
      this.isUnauthorized = false;
      let userAttempts = Number(sessionStorage.getItem('userAttempts'));
      userAttempts = userAttempts + 1;
      if (userAttempts <= 3) {       
        if (this.contextId && this.otp) {
          sessionStorage.setItem('userAttempts', userAttempts.toString());

          let redirectUrl = environment.baseUrl + environment.nationwiideRedirectUrl;
          redirectUrl = redirectUrl.replace('[clientId]', environment.apiKey);
          redirectUrl = redirectUrl.replace('[otp]', this.otp);
          redirectUrl = redirectUrl.replace('[contextId]', this.contextId);
          redirectUrl = redirectUrl.replace('[accountNumber]', this.rbcLandingForm.value.cardLastFourDigit);
  
          window.open(redirectUrl, '_parent');
        }
      }
      else {
        this.isAttemptsExceeded = true;
      }
    }
  }

  openTermsNCondition() {
    let dialogRef = this.dialog.open(AuthTermsConditionComponent, {
      width: '500px',
      data: {}
    });
  }
}
