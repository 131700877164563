import { ErrorHandler } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";

declare var newrelic: any;

export class NewRelicErrorHandler implements ErrorHandler {
    handleError(error: any): void {
        try{
            if(typeof(newrelic) == 'object'){
                if (error instanceof HttpErrorResponse) {
                    let errorText = new Error(`${error.status} - ${error.statusText}; ${error.message}`);
                    newrelic.noticeError(errorText);
                } else {
                    newrelic.noticeError(error);
                }
            }
        } catch(error){
            throw error; 
        }
        
    }
}