import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

sessionStorage.setItem('newRelicApplicationID', environment.newRelicApplicationID);  

const script = document.createElement('script');
script.src = environment.newRelic;
document.head.appendChild(script);  

const celebrusTagsScript = document.createElement('script');
celebrusTagsScript.src = environment.ensightenBootstrap; // Celebrus Tags
document.head.appendChild(celebrusTagsScript);

// Script for TrustArc's Cookie Banner
const trustarcScript = document.createElement('script');
trustarcScript.src = '//consent.trustarc.com/notice?domain=cm-nationwide.com&c=teconsent&js=nj&noticeType=bb&text=true&pcookie&gtm=1'
trustarcScript.setAttribute("crossorigin", "anonymous");
document.head.appendChild(trustarcScript);