import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { OpentextService } from '../services/opentext.service';
import * as xml2js from 'xml2js';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DocumentRequest } from 'src/app/models/document-request.model';
import { Constants } from '../constants/constants';
import { PaymentAuthService } from '../services/payment-auth.service';
import * as moment from 'moment';


@Component({
  selector: 'app-authorize-agreement',
  templateUrl: './authorize-agreement.component.html',
  styleUrls: ['./authorize-agreement.component.scss']
})
export class AuthorizeAgreementComponent implements OnInit {
  contextInfo: any;
  public constants = Constants.CONTENTCONSTANTS;
  constructor(private opentextService: OpentextService,
    private paymentAuthService: PaymentAuthService
  ) { }

  ngOnInit(): void {
  }

  getInstallmentDate(effectiveDate: any) {
    if (effectiveDate) {
      var installmentDate = new Date(effectiveDate);
      installmentDate.setDate(installmentDate.getDate() + 30);
      return moment(installmentDate).format('LL');
    }

    else {
      return '';
    }
  }

  getAuthAgreement() {
    this.contextInfo = this.paymentAuthService.contextData;
    let paymentMethod = this.paymentAuthService.setPaymentMethod(this.contextInfo.cardTypeCode);
    const dt = moment(new Date()).format('YYYYMMDD');
    const docName = `${this.contextInfo.policyNumber}_${dt}.pdf`
    let xmlObject = {};
    let cardInfo = {};
    let docTemplName = "";
    let paymentPlan = "Monthly";
    if (this.contextInfo) {

      if (paymentMethod === this.constants.paymentMethod.creditCard) {
        if (paymentPlan === "Annual")
          docTemplName = "POTBC";
        else {
          docTemplName = "POTRBC";
        }
        cardInfo = {
          IssueDate: moment(new Date(this.contextInfo.issuedDate)).format('LL'),
          PortalURL: this.contextInfo.portalURL,
          Authdays: this.contextInfo.availableCancelDays,
          CallNumber: this.contextInfo.callNumber,
          ValidTime: this.contextInfo.validTime,
          CardType: this.contextInfo.cardType,
          LastFour: this.contextInfo.accountNumber,
          ACHPolicyEffectiveDate: moment(new Date(this.contextInfo.ACHPolicyEffectiveDate)).format('LL')
        }
      } else {
        if (paymentPlan === "Annual")
          docTemplName = "POTACH";
        else {
          docTemplName = "POTRACH";
        }
        cardInfo = {
          IssueDate: moment(new Date(this.contextInfo.issuedDate)).format('LL'),
          PortalURL: this.contextInfo.portalURL,
          Authdays: this.contextInfo.availableCancelDays,
          CallNumber: this.contextInfo.callNumber,
          ValidTime: this.contextInfo.validTime,
          BankRoutingNumber: this.handleMask(this.contextInfo.abaNo),
          BankAccountNumber: this.handleMask(this.contextInfo.acctNo),
          BankAccountType: paymentMethod,
          ACHPolicyEffectiveDate: moment(new Date(this.contextInfo.ACHPolicyEffectiveDate)).format('LL')
        }
      }
      xmlObject = {
        PrintData: {
          Document:
          {
            '$': {
              Name: docTemplName
            }
          },
          PolicyNumber: this.contextInfo.policyNumber,
          Recipient: {
            Name: this.contextInfo.memberName
          },
          ACH: cardInfo
        }
      };
    }

    const builder = new xml2js.Builder({ headless: true });
    const documentContentXml = builder.buildObject(xmlObject);
    console.log(documentContentXml);
    const base64EncodedXml = btoa(unescape(encodeURIComponent(documentContentXml)));
    const documentRequest: DocumentRequest = {
      fileName: 'INPUT',
      pubName: 'Pet_Letters.PUB',
      referenceArea: 'test',
      driverFile: base64EncodedXml
    };

    this.opentextService.getAgreement(documentRequest).subscribe(
      {
        next: (responseData: any) => {
          if (responseData.fileOutput) {

            const binary = atob(responseData.fileOutput.replace(/\s/g, ''));
            const len = binary.length;
            const buffer = new ArrayBuffer(len);
            const view = new Uint8Array(buffer);

            for (let i = 0; i < len; i += 1) {
              view[i] = binary.charCodeAt(i);
            }

            const newBlob = new Blob([view], { type: 'application/pdf' });
            const linkSource = window.URL.createObjectURL(newBlob);

            const link = document.createElement('a');
            link.href = linkSource;
            link.download = docName;
            if (window.navigator.userAgent.includes('Firefox') === true) {
              link.className = 'hidden';
            }
            link.click();
          }
        },
        error: (err) => {
          console.log(err);
        },
        complete: () => {
        }
      });
  }

  handleMask(s: string): string {
    if (s) {
      s = s.replace(/.(?=.{4})/g, '*');
      s = s.toUpperCase();
    } else {
      s = '';
    }
    return s;
  }
}
