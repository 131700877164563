import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthPaymentComponent } from './shared/auth-payment/auth-payment.component';
import { AuthTermsConditionComponent } from './shared/auth-terms-condition/auth-terms-condition.component';
import { AuthorizeAgreementComponent } from './shared/authorize-agreement/authorize-agreement.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { NewRelicErrorHandler } from './shared/helpers/new-relic-error-handler';
import { ProgressSpinnerComponent } from './shared/progress-spinner/progress-spinner.component';
import { ErrorDialogModalComponent } from './shared/error-dialog-modal/error-dialog-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    AuthPaymentComponent,
    AuthTermsConditionComponent,
    AuthorizeAgreementComponent,
    ProgressSpinnerComponent,
    ErrorDialogModalComponent        
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatProgressSpinnerModule 
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: NewRelicErrorHandler
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
