<div class="container">
    <form [formGroup]="rbcLandingForm" (ngSubmit)="authenticateUser()">        
        <div class="col-12 row">
            <div class="col-6  lblColumn">Last 4 digits of Your Card:</div>
            <div class="col-6  controlColumn">
                <mat-form-field appearance="outline" class="txtInput">
                    <input formControlName="cardLastFourDigit" [(ngModel)]="rbcLandingForm.value.cardLastFourDigit"
                        matInput placeholder="Last4 Digits" (keypress)="numbersOnly($event)" maxlength="4">
                </mat-form-field>
            </div>
        </div>      
        <br />
        <div class="col-md-12 text-center">
            <button [disabled]="rbcLandingForm.invalid" mat-raised-button color="primary"
                class="custom-submit-button">Submit</button>
        </div>
        <div class="col-md-12 text-center">
            <mat-error *ngIf="isUnauthorized">
                {{constants.errorMessages.unauthorisedMessage}}
            </mat-error>
            <mat-error *ngIf="isAttemptsExceeded">
                {{constants.errorMessages.attemptExceedeMessage}}
            </mat-error>
        </div>
    </form>
</div>