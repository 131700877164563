import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { Constants } from '../constants/constants';
import { PAYMENTTYPE, PAYMENTTYPECODE } from 'src/app/models/payment-type-enum'


@Injectable({
  providedIn: 'root'
})
export class PaymentAuthService {
  public contextData: any; 
  public constants = Constants.CONTENTCONSTANTS;
  constructor(
    private http: HttpClient
  ) { }

  // public getTokenAndProfile() {
  //   let tokenUrl = environment.baseUrl + environment.authPayment.tokenUrl;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'client_id': environment.authPayment.clientId,
  //       'Authorization': environment.authPayment.basicAuth,
  //       'Content-Type': 'application/x-www-form-urlencoded'
  //     }),
  //   };
  //   let body = {
  //     grant_type: 'password',
  //     username: 'TEST_PORT_1DE7_PT',
  //     password: 'a123456',
  //     scope: 'openid pci',
  //     realm: 'member',
  //     auth_method: 'ping-sts'
  //   };
  //   const data = Object.keys(body)
  //     .map((key) => `${key}=${encodeURIComponent(body[key])}`)
  //     .join('&');
  //   return this.http.post<any>(tokenUrl, data, httpOptions);
  // }

  public paymentAuthorization(authInfo: any, accountId: any): Observable<any> {
    let token = sessionStorage.getItem('accessToken');
    let url = environment.baseUrl + environment.accountsUrl + 'paymentauthorization';
    var messageId = uuid();
    let httpOptions = {
      headers: new HttpHeaders({
        'client_id': environment.apiKey,
        'X-NW-Message-ID': messageId,
        'activeAccountId': accountId.toString(),
        'Authorization': 'Bearer ' + token
      })
    };

    return this.http.post<any>(url, authInfo, httpOptions);
  }

  five9PaymentAuthorization(authInfo: any,): Observable<any> {
    let token = sessionStorage.getItem('accessToken');
    let url = environment.baseUrl + environment.accountsUrl + 'five9paymentauthorization';
    var messageId = uuid();
    let httpOptions = {
      headers: new HttpHeaders({
        'client_id': environment.apiKey,
        'X-NW-Message-ID': messageId
      })
    };

    return this.http.post<any>(url, authInfo, httpOptions);
  }

  setPaymentMethod(typecode: any) {
    switch (typecode.toUpperCase()) {
      case PAYMENTTYPECODE.VI:
      case PAYMENTTYPECODE.MC:
      case PAYMENTTYPECODE.AX:
      case PAYMENTTYPECODE.DI:
        return this.constants.paymentMethod.creditCard;
      case PAYMENTTYPECODE.CHECKING:
      case PAYMENTTYPECODE.SAVING:
      case PAYMENTTYPECODE.SAVINGS:
        return this.constants.paymentMethod.debitCard;
      default:
        return '';
    }
  }
}
